import { useState, useEffect, useCallback } from "react";
import { TABLET_MAX_WIDTH, TABLET_MIN_WIDTH } from "@constants";

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState({ width: 0 });

    if (typeof window !== "undefined") {
        const getWindowDimensions = useCallback(() => {
            const { innerWidth: width, innerHeight: height } = window;
            const isDesktop = width > TABLET_MAX_WIDTH;
            const isMobile = width < TABLET_MIN_WIDTH;
            const isTablet = !(isDesktop || isMobile);
            return {
                width,
                height,
                isDesktop,
                isTablet,
                isMobile,
            };
        }, [window]);

        useEffect(() => {
            setWindowDimensions(getWindowDimensions());
            const handleResize = () =>
                setWindowDimensions(getWindowDimensions());

            window.addEventListener("resize", handleResize);
            return () => window.removeEventListener("resize", handleResize);
        }, []);
    }

    return windowDimensions;
}
