import { createGlobalStyle } from "styled-components";
import { TABLET_MAX_WIDTH, MOBILE_MIN_WIDTH } from "@constants";

export const GlobalStyle = createGlobalStyle`
    h3 {
        font-weight: bold;
        font-size: 28px;
        line-height: 38px;
        letter-spacing: 0.02em;
        margin: 24px 0;

        @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
            font-size: 24px;
        }

        @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
            font-size: 20px;
        }
    }

    .catch-up-section__container {
        & .catchup-section__second {
            width: auto;
            margin-bottom: 54px;

            @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
                width: 100vw;
                margin-left: -100px
            }

            @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
                margin-bottom: 40px;
                margin-left: -24px;
            }
        }
    }
`;
