import styled, { css } from "styled-components";
import { TABLET_MAX_WIDTH, MOBILE_MIN_WIDTH } from "@constants";

export const FAQWrapper = styled.div`
    padding: 0 1.5rem;
    margin: 5rem 0;

    & .development-section {
        padding-bottom: 0;

        & h2 {
            font-weight: 700;
            line-height: 2.4375rem;
            letter-spacing: 0.02em;
            color: rgba(255, 255, 255, 0.8);

            @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
                font-size: 1.75rem;
                line-height: 2.25rem;
                width: 90%;
            }

            @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
                font-size: 1.25rem;
                line-height: 1.875rem;
            }
        }
    }

    & .product-development-item {
        display: block;
        grid-template-columns: none;
        width: 90%;

        &__heading-container {

            @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
                height: fit-content;
                min-height: auto;
            }
        }

        @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
            grid-template-columns: 0fr 4fr;
            column-gap: 0;
            width: 100%;
        }
    }

    & .product-development__heading {
        margin: 0 auto;

        & .page-heading {
            & h2 {
                margin-bottom: 2.5rem;
                text-align: center;

                @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
                    text-align: left;
                }
            }
        }
    }

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        margin: 2.5rem 0;
        ${props =>
            props.withPadding &&
            css`
                padding: 0 6.25rem;
            `}
    }

    ${props =>
        props.withPadding &&
        css`
            @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
                padding: 0 1.5rem;
            }
        `}
`;
