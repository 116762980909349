
export const GLOBAL_APPLICATION_INTERNAL_LINKS = {
    HOME: "/",
    ABOUT_US_PAGE: "/about",
    CASE_STUDIES_PAGE: "/case-studies",
    CONTACT_US: "/contact-us",
    CAREERS: "/careers",
    GDPR: "/gdpr",
    BLOG: "/blog",
    PRIVACY_POLICY: "/privacy-policy",
    TELECOMMUNICATION: "/telecommunication",
    INTERNET_SERVICE_PROVIDERS: "/internet-service-providers",
    COOKIE_POLICY: "/cookie-policy",
    DEDICATED_TEAMS: "/dedicated-teams",
    PRODUCT_DEVELOPMENT: "/product-development",
};