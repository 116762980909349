import React from "react";
import loadable from "@loadable/component";
import { mobileFAQList } from "../components/ApplicationsDevelopment/MobileDevelopment/MobileDevelopment";
import { graphql } from "gatsby";

const Layout = loadable(() => import("@common/Layout/Layout"));
const MobileDevelopment = loadable(() =>
    import(
        "@components/ApplicationsDevelopment/MobileDevelopment/MobileDevelopment"
    )
);
const SEO = loadable(() => import("@components/Common/SEO/SEO"));
const MobileDevelopmentPage = ({ path, data }) => (
    <>
        <SEO
            title="Custom iOS and Android Mobile App Development Services"
            titleTemplate="Impressit"
            SEOData={data.allStrapiSeoDescriptions?.nodes}
            description="Hire mobile app developers with Impressit! Custom mobile app development for iOS and Android with the team of the best tech professionals."
            path={path}
            faqSchema={mobileFAQList}
        />
        <Layout path={path}>
            <MobileDevelopment />
        </Layout>
    </>
);

export default MobileDevelopmentPage;

export const mobileDevelopemntPageQuery = graphql`
    query mobileDevelopmentPageSEODataQuery {
        allStrapiSeoDescriptions {
            nodes {
                Title
                MetaTitle
                PageUrl
            }
        }
    }
`;
