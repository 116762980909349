import React from "react";
import useWindowDimensions from "@hooks/useWindowDimensions";
import loadable from "@loadable/component";
import { FAQWrapper } from "./FAQSection.style";

const DropDownListSection = loadable(() =>
    import("@common/CommonSections/DropDownListSection/DropDownListSection")
);

const FAQSection = ({ title, FAQList, withPadding }) => {
    const { isMobile } = useWindowDimensions();

    return (
        <FAQWrapper withPadding={withPadding}>
            <DropDownListSection
                heading={title}
                sectionItems={FAQList}
                isMobile={isMobile}
                itemsMargin="3.125rem"
                changeTitleOnClick
                maxItemsAmount={4}
            />
        </FAQWrapper>
    );
};

export default FAQSection;
